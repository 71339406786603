import React from "react";
import AnimatedLetters from "../AnimatedLetters";
import "../styles.css";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 3rem;
  color: #f9f9f9;
  margin-top: 10rem;
`;

const Home = () => {
  return (
    <>
      <div className="App">
        <div className="container">
          <div className="row">
            <Title>
              <AnimatedLetters />
            </Title>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
