import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import '../styles.css';

const Container = styled.div`
  display: flex;
  height: 100vh;
  padding-top: 1rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-top: 10rem;
`;

const Page1 = () => {
  return (
    <Container>
      <div className="container">
      <Title>Хлам</Title>
      <div className='btn btn-primary'>
        <MyButton />
        <MyButton />
        </div>
        </div>
    </Container>
  );
};

function MyButton() {
  const [count, setCount] = useState(0);

  function handleClick() {
    setCount(count + 1);
  }

  return (
    <button onClick={handleClick}>
      Жмакнуто {count} раз!
    </button>
  );
}

export default Page1;
