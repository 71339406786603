import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Page1 from './pages/Page1';
import './styles.css';
import PageNotFound from "./404Page";
import Home from "./pages/home";
import TableToHtmlConverter from './pages/to-html-converter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Navbar = styled.nav`
  background-color: #2b2b2b;
  padding: 1rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem 0;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;


function App() {
  return (
    <><Router>
      <Navbar>
        <NavLink to="/">Важная страница</NavLink>
        <NavLink to="/page1">Не важная</NavLink>
        <NavLink to="/to-html-converter">Ворд в хтмл конвертер</NavLink>
      </Navbar>
      <Container>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/page1" element={<Page1 />} />
          <Route path="/to-html-converter" element={<TableToHtmlConverter />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Container>
    </Router>
</>
  );
}

export default App;