import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const AnimatedLetters = () => {
  const lettersRef = useRef([]);
  const letters = ['Ж', 'О', 'П', 'А','(('];

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
    lettersRef.current.forEach((letter, index) => {
      tl.to(letter, {
        duration: 0.8,
        opacity: 1,
        scale: 1.5,
        ease: "power1.inOut",
      }).to(letter, {
        duration: 0.8,
        opacity: 0,
        scale: 1,
        ease: "power1.inOut",
        delay: 0.5
      }, `+=0.5`);
    });
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', fontSize: '15rem', color: '#b2b2b2' }}>
      {letters.map((letter, index) => (
        <div
          key={index}
          ref={el => (lettersRef.current[index] = el)}
          style={{ opacity: 0, position: 'absolute' }}
        >
          {letter}
        </div>
      ))}
    </div>
  );
};

export default AnimatedLetters;
