import React, { useRef, useState } from 'react';

function TableToHtmlConverter() {
  const contentRef = useRef(null);
  const [htmlOutput, setHtmlOutput] = useState('');
  const [removeStyles, setRemoveStyles] = useState(false);

  const stripStylesAndTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const elementsWithStyles = tempDiv.querySelectorAll('[style]');
    elementsWithStyles.forEach((el) => el.removeAttribute('style'));

    const spanAndPElements = tempDiv.querySelectorAll('span, p');
    spanAndPElements.forEach((el) => {
      const parent = el.parentNode;
      while (el.firstChild) {
        parent.insertBefore(el.firstChild, el);
      }
      parent.removeChild(el);
    });

    const oPTags = tempDiv.querySelectorAll('o\\:p');
    oPTags.forEach((el) => el.remove());

    const tableElements = tempDiv.querySelectorAll('table, td, th');
    tableElements.forEach((el) => {
      el.removeAttribute('class');
      el.removeAttribute('border');
      el.removeAttribute('cellspacing');
      el.removeAttribute('cellpadding');
      el.removeAttribute('width');
      el.removeAttribute('valign');
    });

    return tempDiv.innerHTML;
  };

  const handleExtractHtml = () => {
    if (contentRef.current) {
      let tableHtml = contentRef.current.innerHTML;
      if (removeStyles) {
        tableHtml = stripStylesAndTags(tableHtml);
      }
      setHtmlOutput(tableHtml);
    }
  };

  return (
    <div className="container">
      <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
        <h1 className="text-center">Ворд в HTML</h1>
        
        <div className="row w-100 mt-4">
          <div className="col-12 col-md-6 mb-3 mb-md-0">
            <div
              ref={contentRef}
              contentEditable="true"
              style={{
                border: '1px solid black',
                padding: '10px',
                minHeight: '400px',
              }}
            ></div>
          </div>

          <div className="col-12 col-md-6">
            <textarea
              value={htmlOutput}
              readOnly
              rows="15"
              style={{ width: '100%', height: '100%' }}
              className="form-control"
            />
          </div>
        </div>

        <div className="form-check mt-3">
          <input
            type="checkbox"
            className="form-check-input"
            checked={removeStyles}
            onChange={() => setRemoveStyles(!removeStyles)}
          />
          <label className="form-check-label">Удалить хуйню</label>
        </div>

        <button
          onClick={handleExtractHtml}
          className="btn btn-primary mt-3"
        >
          Получить HTML
        </button>
      </div>
    </div>
  );
}

export default TableToHtmlConverter;
